<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">分销管理</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >分销提现</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div
                            title="姓名"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">姓名:</span>
                            <el-input
                                v-model="userName"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入姓名"
                                clearable
                            />
                        </div>
                        <div
                            title="手机号"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">手机号:</span>
                            <el-input
                                v-model="mobile"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入手机号"
                                clearable
                            />
                        </div>
                        <div
                            title="到账时间"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">到账时间:</span>
                            <el-date-picker
                                v-model="value1"
                                size="small"
                                style="width:auto"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
								value-format="yyyy-MM-dd HH:mm:ss"
                                clearable
                            >
                            </el-date-picker>
                        </div>
                        <div>
                            <el-button
                                class="bgc-bv"
                                @click="getData1()"
                            >查询</el-button>
                            <el-button
                                class="bgc-bv"
                                @click="doExport()"
                            >导出</el-button>
                        </div>
                    </div>
                </div>
                <div style="padding:10px 30px 0">提现总金额：{{sum}}</div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="tableData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                            />
                            <el-table-column
                                label="姓名"
                                align="center"
                                prop="userName"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="手机号"
                                align="center"
                                prop="mobile"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="提现金额"
                                align="center"
                                prop="withdrawAmount"
                            />
                            <el-table-column
                                label="申请提现时间"
                                align="center"
                                prop="createTime"
                            />
                            <el-table-column
                                label="到账时间"
                                align="center"
                                prop="arriveTime"
                            />
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="apiData"
                    @sizeChange="sizeChange"
                    @getData="getData1"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "WithdrawalList",
    components: {
        Empty,
        PageNum,
    },
    mixins: [List],
    data() {
        return {
            userName: "",
            mobile: "",
            value1: [],
            sum: "0",
        };
    },
    computed: {},
    created() {
        if (this.$route.query.stu) {
            this.mobile = this.$route.query.mobile;
        }
        this.getData1();
    },
    methods: {
        getData1(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                userName: this.userName,
                mobile: this.mobile,
            };
            if (this.value1 && this.value1.length) {
                params.endDate = this.value1[1];
                params.startDate = this.value1[0];
            }
            this.doFetch(
                {
                    url: "/biz/distribute/withdraw/pageList",
                    params,
                    pageNum,
                },
                true,
                2
            );
            this.getSum(params);
        },
        doExport() {
            const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                userName: this.userName,
                mobile: this.mobile,
                distributeId: this.distributeId,
            };
            if (this.value1.length) {
                params.endDate = this.value1[1];
                params.startDate = this.value1[0];
            }
            this.$post(
                "/biz/distribute/withdraw/exportList",
                params,
                3000,
                true,
                2
            ).then((res) => {
                ;
                window.open(res.data);
            });
        },
        getSum(params) {
            this.$post(
                "/biz/distribute/withdraw/sum",
                params,
                3000,
                true,
                2
            ).then((res) => {
                this.sum = res.data;
            });
        },
    },
};
</script>
<style lang="less"></style>
